<template>
  <div class="mct-form">
    <mct-topo-lista :titulo="titulo" :subtitulo="`Listagem de ${titulo}`" v-show="!showModal"></mct-topo-lista>    

    <div class="clearfix">
      <div style="float: left;" class="form-inline">
        <div style="padding: 4px 0px;" v-show="!showModal">
          <button type="button" class="btn btn-success" aria-label="Left Align" @click="incluirNovo($event)">
            <span class="fa fa-plus fa-lg" aria-hidden="true"></span> Novo Registro </button>
        </div>                
          <button type="button" class="btn btn-light" aria-label="Left Align" @click="getData()">
            <span class="fa fa-refresh fa-lg" aria-hidden="true"></span></button>                          
      </div>    
    </div>
    <div class="row mct-form-filter">
      <div class="col-3">
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text">Situação</label>
          </div>            
          <select class="form-control" v-model="situacao">
            <option value="-1">TODOS</option>
            <option value="0">CANCELADO</option>
            <option value="1">EM ANDAMENTO</option>
            <option value="2">AGUARDANDO</option>
            <option value="3">FINALIZADO</option>
          </select>              
        </div>
      </div>
      <div class="col-4">
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text">Curso</label>
          </div>            
          <input type="text" 
            class="form-control"
            placeholder="Pesquisa"
            v-model="curso">              
        </div>
      </div>
      <div class="col-5">
        <div class="input-group">
          <div class="input-group-prepend">
            <label class="input-group-text">Período</label>
          </div>
          <div>           
            <datepicker
              placeholder="Data inicial"
              v-model="dataini" 
              lang='pt-br'
              type="date"
              input-class="form-control"
              value-type="YYYY-MM-DD" 
              format="DD/MM/YYYY"
              ></datepicker>             
          </div>
           <div class="input-group-prepend">
            <label class="input-group-text">até</label>
          </div>
          <div>           
            <datepicker
              placeholder="Data final"
              v-model="datafin" 
              lang='pt-br'
              type="date"
              input-class="form-control"
              value-type="YYYY-MM-DD" 
              format="DD/MM/YYYY"
              ></datepicker>
            </div>
        </div>
      </div>
      <div class="h-divider clear"></div>
      <div class="col-5">
        <button type="button" class="btn btn-secondary btn-sm mr-1" aria-label="Left Align" @click="getData($event)">
            <span class="fa fa-search" aria-hidden="true"></span> Buscar 
        </button> 
        <button type="button" class="btn btn-danger btn-sm" aria-label="Left Align" @click="limpaEdits()">
            <span class="fa fa-eraser" aria-hidden="true"></span> Limpar 
        </button>
      </div>
    </div>
    <slot>
        <div class="mct-grid">
            <div class="mct-grid-scroll">         
                <table class="table table-striped table-bordered table-sm table-hover">
                    <thead>
                        <tr>
                            <th :style="showModal ? 'width: 35px!important;' : 'width: 75px!important;'"></th>
                            <th style="width: 50px;" class="text-center">Agend.</th>
                            <th>Curso / Módulo / CH</th>
                            <th style="width: 80px;" class="text-right">Data Início</th>
                            <th style="width: 80px;" class="text-right">Data Fim</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row,index) in data" :key="index"  @click.prevent.stop="setDescricao($event,row)">
                        <td>
                          <div class="btn-group pt-2">
                            <!--<i class="fa fa-pencil text-white mct-fa bg-primary mr-1" v-if="row.horari_situacao === 1 || row.horari_situacao === 2" aria-hidden="true" title="Editar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.horari_codigo}/${row.cur_codigo}/${row.turm_codigo}/${row.ser_codigo}/editar`)"></i>-->
                            <a class="fa fa-pencil text-white mct-fa bg-primary mr-1 btn btn-sm" style="max-height: 22px; padding-top: 1px;" role="button" :class="row.horari_situacao === 0 || row.horari_situacao === 3 ? 'disabled' : ''" :aria-disabled="row.horari_situacao === 0 || row.horari_situacao === 3" aria-hidden="true" title="Editar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.horari_codigo}/${row.cur_codigo}/${row.turm_codigo}/${row.ser_codigo}/editar`)"></a>
                            <a class="fa fa-ban text-white bg-danger mct-fa mr-1 btn btn-sm" style="max-height: 22px; padding-top: 1px;" role="button" :class="row.horari_situacao === 0 || row.horari_situacao === 3 ? 'disabled' : ''" :aria-disabled="row.horari_situacao === 0 || row.horari_situacao === 3" aria-hidden="true" title="Cancelar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.horari_codigo}/${row.cur_codigo}/${row.turm_codigo}/${row.ser_codigo}/deletar`)"></a>
                            <i class="fa fa-search text-white bg-secondary mct-fa mr-1" aria-hidden="true" title="Visualizar Agendamento" v-show="!showModal" @click="redirect($event,`${$route.fullPath.replace('/lista','')}/${row.horari_codigo}/${row.cur_codigo}/${row.turm_codigo}/${row.ser_codigo}/visualizar`)"></i>
                            <i class="fa fa-hand-pointer-o text-white bg-info mct-fa mr-1" aria-hidden="true" v-show="showModal" @click.prevent.stop="setDescricao($event,row)"></i>
                          </div>
                        </td>
                        <td><span class="mct-grid-span-02 d-block text-center pt-2">{{row.horari_codigo}}</span></td>
                        <td>
                          <div class="row">
                            <div class="col-6">
                              <h1 class="mct-grid-h1" style="white-space: pre-wrap; word-wrap:break-word;">{{row.cur_codigo +' - '+ row.cur_nome }}<span class="font-italic">{{' | '+row.ser_descricao}}</span> </h1>
                              <span class="mct-grid-span-01 d-block text-primary">{{row.horari_descricao+' | '+'CH: '}}{{row.horari_ch | horamin}}hs</span>                              
                              <span class="badge badge-primary" v-if="row.horari_situacao === 1"><i class="fa fa-clock-o" aria-hidden="true"></i> em andamento</span>
                              <span class="badge badge-danger" v-else-if="row.horari_situacao === 0"><i class="fa fa-ban" aria-hidden="true"></i> cancelado</span>
                              <span class="badge badge-info" v-else-if="row.horari_situacao === 2"><i class="fa fa-hourglass-start" aria-hidden="true"></i> aguardando</span>
                              <span class="badge badge-success" v-else-if="row.horari_situacao === 3"><i class="fa fa-check" aria-hidden="true"></i> finalizado</span>
                            </div>
                            <div class="col-1">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">MÓD.</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.ser_serie}}</span>                              
                            </div>
                            <div class="col-2">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURMA</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.turm_identificacao}}</span>
                            </div>
                            <div class="col-1">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">TURNO</span>
                              <!--<span class="d-block text-center"><i class="fa fa-sun-o text-warning" aria-hidden="true"></i> MAT</span>-->
                              <span class="mct-grid-span-02 fa-stack text-center">
                                <i class="fa fa-sun-o text-warning" :class="row.turn_tipo == 0 ? 'fa-daytime' : ''" v-if="row.turn_tipo == 0 || row.turn_tipo == 1 || row.turn_tipo == 2"></i>
                                <i class="fa fa-moon-o text-primary" v-if="row.turn_tipo == 0 || row.turn_tipo == 3"></i> 
                                <i class="fa fa-clock-o text-info" v-if="row.turn_tipo == 4"></i> 
                                {{row.turn_abreviatura}}
                              </span>
                              <!--<span class="d-block text-center"><i class="fa fa-moon-o text-primary" aria-hidden="true"></i> NOT</span>-->
                            </div>
                            <div class="col-2">
                              <span class="mct-grid-span-00 mct-text-dark-light d-block text-center">ETAPAS</span>
                              <span class="mct-grid-span-02 d-block text-center">{{row.horari_qtddias}}</span>
                            </div>
                            
                          </div>
                        </td>
                        <td class="text-right"><span class="mct-grid-span-02 d-block pt-2"><i class="fa fa-calendar mct-text-dark-light" aria-hidden="true" v-if="row.horari_data_inicial != '' && row.horari_data_inicial != null"></i> {{row.horari_data_inicial | data}}</span></td>
                        <td class="text-right"><span class="mct-grid-span-02 d-block pt-2"><i class="fa fa-calendar mct-text-dark-light" aria-hidden="true" v-if="row.horari_data_final != '' && row.horari_data_final != null"></i> {{row.horari_data_final | data}}</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </slot>
    <mct-rodape-lista :data="data" :query="query" @navigate="navigate" @getData="getData"></mct-rodape-lista>        
  </div>
</template>
<script>
  import Vue from 'vue'
  
  import axios from '@/http'
  import moment from 'moment'
  import Datepicker from 'vue2-datepicker'
  import 'vue2-datepicker/index.css';
  import 'vue2-datepicker/locale/pt-br';

  import MctTopoLista from '@/components/comum/MctTopoLista.vue'
  import MctRodapeLista from '@/components/comum/MctRodapeLista.vue'
  import MctGridLista from '@/components/comum/MctGridLista.vue'
  import MctMixinsList from '@/components/funcoes/MctMixinsList'

  export default {
    name: 'EscAgendamentoLista',
    mixins: [MctMixinsList],
    components: {
        'MctTopoLista': MctTopoLista,
        'MctRodapeLista': MctRodapeLista,
        'MctGridLista' : MctGridLista,
        Datepicker
    },        
    props: ['source', 'titulo', 'showModal'],
    data() {
      return {
        id: '',
        showAvancado: false, 
        curso: '',
        situacao: -1,
        dataini: null,
        datafin: null
      }
    },
    watch: {
        source: function () {
          this.query.column = ''
          this.query.search_column = ''
          this.query.page = 1
          this.query.direction = 'asc'
          this.query.per_page = 10
          this.query.search_operator = 'equal'
          this.query.search_input = ''
          this.query.search_input2 = ''
          this.filters = {}
          this.getData()      
        }
    },    
    methods: {
        async getData() {
          if (typeof this.source !== "undefined" && typeof this.source !== "null" && this.source !== 'undefined/lista'){
          axios.get(this.source,{params:  {
                  column: this.query.column,
                  direction: this.query.direction,
                  page: this.query.page,
                  per_page: this.query.per_page,
                  search_column: this.query.search_column,
                  search_operator: this.query.search_operator,
                  search_input: this.query.search_input,
                  situacao: this.situacao,
                  curso: this.curso,
                  dataini: this.dataini,
                  datafin: this.datafin
                  /*,
                  filters: this.filtros*/
              },
              paramsSerializer: function (params) {
                return jQuery.param(params)
              }
              })
              .then(response => {
                this.data = response.data.data
                this.columns = response.data.columns
                if (typeof response.data.filters != 'undefined'){
                  this.filters = response.data.filters
                }else{
                  this.filters = response.data.columns
                }
                this.id = response.data.primary_key
                this.$emit('setData', this.data, this.columns, this.query)
              })
              .catch(response => {
                console.log(response.message)
              })
          }
      },
      navigate (page) {
            this.query.page = page
            this.getData()
      },
      toggleOrder(column) {
        if(column === this.query.column) {
          if(this.query.direction === 'desc') {
            this.query.direction = 'asc'
          } else {
            this.query.direction = 'desc'
          }
        } else {
          this.query.column = column
          this.query.direction = 'asc'
        }
        this.getData()
      },      
      setDescricao (data ){
          this.$emit('setDescricao', data)
      },
      toogleAvancado (data){
        this.showAvancado = data
      },
      redirect (ev, link) {
          ev.preventDefault();
          
          this.$router.push(link)
      },
      limpaEdits() {
        this.curso = '';
        this.situacao = -1;
        this.dataini = null;
        this.datafin = null;
        this.getData();  
      }  
    },
  }
</script>

<style scoped>
  .mct-grid-h1{
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 0.8rem; 
  }
  .mct-grid-span-01{
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 0.6rem; 
  }
  .mct-grid-span-00{
    font-size: 0.6rem;
    font-weight: 400;
  }
  .mct-grid-span-02{
    font-size: 0.8rem;
    font-weight: 400;
    line-height: 1.8rem;
  }
  .fa-daytime{
    margin-right: -10px;
  }

</style>
